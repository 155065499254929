import React from 'react'
import { cx } from 'helpers'
import { useDevice } from 'device'

import FadeImage from './FadeImage'
import { useImageRotator } from '../hooks/useImageRotator'


const IngredientsImages = () => {
  const { isMobile } = useDevice()
  const images = useImageRotator()

  return (
    <div className={cx('grid', isMobile ? 'mt-24 grid-cols-5' : 'rotate-45 grid-cols-4 gap-16')}>
      {images.map((src, index) => <FadeImage src={src} key={index} /> )}
    </div>
  )
}


export default IngredientsImages
