import almondImage from './almond.png?src-loader'
import artemisiaImage from './artemisia.png?src-loader'
import basilicImage from './basilic.png?src-loader'
import bergamotImage from './bergamot.png?src-loader'
import birchImage from './birch.png?src-loader'
import blackSesameImage from './black-sesame.png?src-loader'
import blueberryImage from './blueberry.png?src-loader'
import cannabisImage from './cannabis.png?src-loader'
import charcoalImage from './charcoal.png?src-loader'
import cherryImage from './cherry.png?src-loader'
import chocolateImage from './chocolate.png?src-loader'
import coconutImage from './coconut.png?src-loader'
import cottonCandyImage from './cotton-candy.png?src-loader'
import frankincenseImage from './frankincense.png?src-loader'
import freesiaImage from './freesia.png?src-loader'
import ginImage from './gin.png?src-loader'
import ginger2Image from './ginger-2.png?src-loader'
import gingerImage from './ginger.png?src-loader'
import greenAppleImage from './green-apple.png?src-loader'
import guaiacImage from './guaiac.png?src-loader'
import heliotropeImage from './heliotrope.png?src-loader'
import honeyImage from './honey.png?src-loader'
import incenseImage from './incense.png?src-loader'
import junipeberryImage from './junipeberry.png?src-loader'
import lavenderImage from './lavender.png?src-loader'
import licorice2Image from './licorice-2.png?src-loader'
import licoriceImage from './licorice.png?src-loader'
import limeImage from './lime.png?src-loader'
import lycheeImage from './lychee.png?src-loader'
import magnoliaImage from './magnolia.png?src-loader'
import melonImage from './melon.png?src-loader'
import mixedNutsImage from './mixed-nuts.png?src-loader'
import muskImage from './musk.png?src-loader'
import orangeImage from './orange.png?src-loader'
import pineNeedleImage from './pine-needle.png?src-loader'
import plumImage from './plum.png?src-loader'
import pumpkinImage from './pumpkin.png?src-loader'
import redPepperImage from './red-pepper.png?src-loader'
import redPopyImage from './red-popy.png?src-loader'
import seawateImage from './seawate.png?src-loader'
import waterMelonImage from './water-melon.png?src-loader'


export const allImages = [
  gingerImage,
  artemisiaImage,
  cherryImage,
  ginImage,
  bergamotImage,
  guaiacImage,
  licoriceImage,
  basilicImage,
  charcoalImage,
  coconutImage,
  limeImage,
  chocolateImage,
  freesiaImage,
  plumImage,
  seawateImage,
  blueberryImage,
  almondImage,
  redPepperImage,
  pineNeedleImage,
  blackSesameImage,
  birchImage,
  cannabisImage,
  cottonCandyImage,
  frankincenseImage,
  ginger2Image,
  greenAppleImage,
  heliotropeImage,
  honeyImage,
  incenseImage,
  junipeberryImage,
  lavenderImage,
  licorice2Image,
  lycheeImage,
  magnoliaImage,
  melonImage,
  mixedNutsImage,
  muskImage,
  orangeImage,
  pumpkinImage,
  redPopyImage,
  waterMelonImage,
]
