import { useState, useLayoutEffect } from 'react'

import { allImages } from '../images'
import { getRandomIndex, getRandomUniqueImage } from '../utils'


const DELAY_CHANGE_IMAGE_MS = 700
const COUNT_OF_SHOWED_IMAGES = 20
const INITIAL_IMAGES = allImages.slice(0, COUNT_OF_SHOWED_IMAGES).map(({ src }) => src)

export const useImageRotator = () => {
  const [ images, setImages ] = useState(INITIAL_IMAGES)

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setImages((prevImages) => {
        const randomIndex = getRandomIndex(prevImages.length)

        return prevImages.map((prevImage, idx) =>
          idx === randomIndex ? getRandomUniqueImage(allImages, prevImages) : prevImage
        )
      })
    }, DELAY_CHANGE_IMAGE_MS)

    return () => clearInterval(interval)
  }, [])

  return images
}
