import React, { useState, useEffect } from 'react'
import { cx } from 'helpers'
import { useDevice } from 'device'

import { Image } from 'components/dataDisplay'


type FadeImageProps = {
  src: string
  alt?: string
}

const TRANSITION_DURATION = 400

const FadeImage: React.FC<FadeImageProps> = ({ src, alt = '' }) => {
  const { isMobile } = useDevice()

  const [ currentSrc, setCurrentSrc ] = useState(src)
  const [ isVisible, setIsVisible ] = useState(true)

  useEffect(() => {
    if (src !== currentSrc) {
      setIsVisible(false)

      const timeout = setTimeout(() => {
        setCurrentSrc(src)
        setIsVisible(true)
      }, TRANSITION_DURATION)

      return () => clearTimeout(timeout)
    }
  }, [ src, currentSrc ])

  return (
    <Image
      alt={alt}
      src={currentSrc}
      remWidth={isMobile ? 76 : 324}
      className={cx(
        'ease object-contain transition-opacity duration-300',
        isMobile ? 'size-[76rem]' : 'size-[324rem]',
        isVisible ? 'opacity-100' : 'opacity-0'
      )}
    />
  )
}


export default FadeImage
