export default {
  title: {
    en: 'Find your<br/>scent profile',
  },
  subtitle: {
    en: 'Do your tastes veer towards fruity florals or rich, spicy woods? Take our quiz to determine your scent profile.',
  },
  takeQuiz: {
    en: 'Take our quiz',
  },
}
