import React from 'react'
import { cx } from 'helpers'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'

import messages from '../messages'


const TitleAndSubtitle = () => {
  const { isMobile } = useDevice()

  return (
    <>
      <Text className={cx(isMobile && 'mt-8')} style={isMobile ? 'h4' : 'h3'} message={messages.title} html />
      <Text className="mt-16" style={isMobile ? 'p2' : 'p1'} message={messages.subtitle} />
    </>
  )
}


export default TitleAndSubtitle
