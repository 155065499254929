import React from 'react'
import { cx } from 'helpers'
import { useDevice } from 'device'

import TakeQuizButton from './components/TakeQuizButton'
import TitleAndSubtitle from './components/TitleAndSubtitle'
import IngredientsImages from './components/IngredientsImages'


type QuizSectionPropsAB = {
  className?: string
}


const QuizSectionAB: React.FC<QuizSectionPropsAB> = ({ className }) => {
  const { isMobile } = useDevice()

  if (isMobile) {
    return (
      <div className={cx('bg-light-beige p-24 text-center', className)}>
        <TitleAndSubtitle />
        <IngredientsImages />
        <TakeQuizButton />
      </div>
    )
  }

  return (
    <div className={cx('bg-light-beige relative flex h-[688px] items-center justify-center overflow-hidden', className)}>
      <IngredientsImages />
      <div className="bg-gold-30 absolute w-[720rem] p-72 text-center">
        <TitleAndSubtitle />
        <TakeQuizButton />
      </div>
    </div>
  )
}


export default QuizSectionAB
